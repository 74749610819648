import './App.css';
import { Box, ButtonBase, Grid, styled, Typography} from '@mui/material';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from "react-router-dom";
// @ts-ignore
import TitleBar from './TitleBar/TitleBar.tsx';
// @ts-ignore
import Home from './Home/Home.tsx'

function App() {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <TitleBar />
            <Home />
        </Box>
    );
}

export default App;
