import React from 'react';
import { Button, Box, Container, Typography } from '@mui/material';
import { Link } from "react-router-dom";
import IGalleryEntry from './IGalleryEntry';
import itemData from './gallery.json';
import {useParams} from "react-router-dom";
// @ts-ignore
import TitleBar from '../TitleBar/TitleBar.tsx';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import BrushIcon from '@mui/icons-material/Brush';
import CropDinIcon from '@mui/icons-material/CropDin';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';

export default function GalleryViewer() {
    let {image_index} = useParams();
    let id = 0;
    if (typeof image_index === 'string' && isFinite(Number(image_index))) {
        id = Number(image_index);
    }
    const image = (itemData as IGalleryEntry[])[id];
    return (
        <Box sx={{display: {xs: 'block', sm: 'grid'}}} gridTemplateRows={'115px 36px 1fr'} height={'inherit'}>
            <TitleBar />
            <Box
                display="flex"
                justifyContent="center"
            >
                <Link to="/gallery">
                    <Button>Back to Gallery</Button>
                </Link>
            </Box>
            <Container maxWidth="xl"
                style={{
                    paddingTop:"24px",
                    paddingBottom:"24px",
                }}
                sx={{height: {xs: 'unset', sm: 'calc(100vh - 115px - 36px)'}}}>
                
                <Box
                    display="flex"
                    justifyContent="space-between"
                    height={'100%'}
                >
                    <Box
                        sx={{display: {xs: 'none', sm: 'none', md: 'flex'}}}
                        alignSelf="center"
                        flexDirection='column'>
                        <Typography
                            align='center'
                            variant="h3">
                            {image.title}
                        </Typography>
                        <Typography
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }}
                            variant="body1">
                            <AspectRatioIcon />
                            {image.size}
                        </Typography>
                        <Typography
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }}
                            variant="body1">
                            <BrushIcon />
                            {image.medium}
                        </Typography>
                        <Typography
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }}
                            variant="body1">
                            <CropDinIcon />
                            {image.surface}
                        </Typography>
                        <Typography
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }}
                            variant="body1">
                            <CurrencyPoundIcon />
                            {image.sold ? "Sold" : "Enquire"}
                        </Typography>
                    </Box>
                    <Box sx={{height: {xs: 'unset', sm: '100%'}, width: {md: '100%', lg: '900px'}, margin: {xs: '0px', sm: 'auto'}}}
                    justifyContent="center"
                    flexDirection="column"
                    display="flex">
                        <Typography
                            sx={{display: {sm: 'block', md: 'none'}}}
                            align='center'
                            variant="h4">
                            {image.title}
                        </Typography>
                        <img
                            src={`/gallery/large/${image.jpg}`}
                            alt={image.title}
                            style={{height: '100%', width: '100%', objectFit:'contain'}}
                            loading="lazy"
                        />
                        <Box sx={{display: {sm: 'flex', md: 'none'}}}
                            justifyContent="space-around"
                            flexDirection='row'>
                            <Typography
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}
                                variant="body1">
                                <AspectRatioIcon />
                                {image.size}
                            </Typography>
                            <Typography
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}
                                variant="body1">
                                <BrushIcon />
                                {image.medium}
                            </Typography>
                            <Typography
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}
                                variant="body1">
                                <CropDinIcon />
                                {image.surface}
                            </Typography>
                            <Typography
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                }}
                                variant="body1">
                                <CurrencyPoundIcon />
                                {image.sold ? "Sold" : "Enquire"}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}