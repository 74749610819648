import { Button, Grid, Link, Paper, Typography } from '@mui/material';
import { IExhibition } from './IExhibition';
import dateFormat from "dateformat";
import DateRangeIcon from '@mui/icons-material/DateRange';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LanguageIcon from '@mui/icons-material/Language';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import { Box, margin } from '@mui/system';

export default function ExhibitionCard(props: {exhibition: IExhibition}) {
    let tel = <div></div>;
    if (props.exhibition.contact.tel) {
        tel = <Link
            variant="subtitle2"
            href={props.exhibition.contact.tel}
            style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
            }}
        >
            <CallIcon />
            {props.exhibition.contact.tel}
        </Link>
    }
    let email = <div></div>;
    if (props.exhibition.contact.email) {
        email = <Link
            variant="subtitle2"
            href={`mailto:${props.exhibition.contact.email}`}
            style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
            }}
        >
            <EmailIcon />
            {props.exhibition.contact.email}
        </Link>
    }
    let instagram = <div></div>;
    if (props.exhibition.contact.instagram) {
        instagram = <Link
            variant="subtitle2"
            href={`https://www.instagram.com/${props.exhibition.contact.instagram}`}
            style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
            }}
        >
            <InstagramIcon />
            {props.exhibition.contact.instagram}
        </Link>
    }
    let facebook = <div></div>;
    if (props.exhibition.contact.facebook) {
        facebook = <Link
            variant="subtitle2"
            href={`https://www.facebook.com/${props.exhibition.contact.facebook}`}
            style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
            }}
        >
            <FacebookIcon />
            {props.exhibition.contact.facebook}
        </Link>
    }
    let website = <div></div>;
    if (props.exhibition.website) {
        website = <Link
            variant="subtitle2"
            href={props.exhibition.website.url}
            style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
            }}
            sx={{display: props.exhibition.website ? 'block' : 'none'}}
        >
            <LanguageIcon />
            {props.exhibition.website.text}
        </Link>
    }
    let location = <div></div>;
    if (props.exhibition.location.googleMaps) {
        location = <Link
            href={props.exhibition.location.googleMaps}>
                {props.exhibition.location.name}
            </Link>
    } else {
        location = <span>{props.exhibition.location.name}</span>
    }
    return (
        <Paper variant="outlined"
            sx={{
                padding: '10px'
            }}>
            <Grid
                container
                direction="row">
                <Grid
                    item
                    sm={4}
                    sx={{display: { xs: 'none', sm: 'block' } }}
                >
                    <Box
                        sx={{display: { xs: 'none', sm: 'flex' } }}>
                        <img
                            src={`${props.exhibition.image}`}
                            alt={props.exhibition.title}
                            loading="lazy"
                            height={200}
                            style={{margin: 'auto'}}
                        />
                    </Box>
                    <Typography
                        variant="subtitle2"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}>
                            <DateRangeIcon />
                            {dateFormat(props.exhibition.date.startDate, 'dddd dS')} to {dateFormat(props.exhibition.date.endDate, 'dddd dS mmmm, yyyy')}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}>
                            <LocationOnIcon />
                            {location}
                    </Typography>
                </Grid>
                <Grid item sm={8}>
                    <Typography
                        variant="h4">
                        {props.exhibition.title}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}
                        sx={{display: { xs: 'block', sm: 'none' } }}>
                            <DateRangeIcon />
                            {dateFormat(props.exhibition.date.startDate, 'dddd dS')} to {dateFormat(props.exhibition.date.endDate, 'dddd dS mmmm, yyyy')}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}
                        sx={{display: { xs: 'block', sm: 'none' } }}>
                            <LocationOnIcon />
                            {location}
                    </Typography>
                    <Typography
                        variant="body2">
                        {props.exhibition.description}
                    </Typography>
                    {website}
                    {facebook}
                    {instagram}
                    {email}
                    {tel}
                </Grid>
            </Grid>
        </Paper>
    )
}