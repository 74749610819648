import { Box, Button, ButtonGroup, Grid, Paper, Typography } from "@mui/material";
import { Container } from "@mui/system";
// @ts-ignore
import TitleBar from "../TitleBar/TitleBar.tsx";


export default function ArtStory() {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <TitleBar />
            <Container maxWidth="lg">
                <Grid container direction="row" spacing={4}>
                    <Grid item xs={0} sm={4} md={3} sx={{display: { xs: 'none', sm: 'block' } }}>
                        <Box>
                            <img src="/images/profile.webp" style={{width: '100%'}}/>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9}>
                        <Typography
                            variant="h4">
                            Mandy Jillings
                        </ Typography>
                        <Typography>
                            Mandy Jillings was born in England and at the age of 16 her family immigrated to Johannesburg, South Africa. Art was initially expressed through music and at 17 years old she played the piano at the Johannesburg Music Festival.
                        </Typography>
                        <Typography>
                            Returning to the UK in 1985 she married and started a family. During this time her interest in embroidery began and she set up an embroidery group which made and donated works to a local hospital.  In 1999 she enrolled at the Royal School of Needlework where she attained a distinction in every technique along with a Highly Commended award.
                        </Typography>
                        <Typography>
                            After a serious illness in 2013, Liz Hauck introduced her to drawing as a means of managing pain levels during her 5 years of recovery. Self-taught, her work is now expressed mainly through mixed media.
                        </Typography>
                        <Typography
                            variant="h4">
                            Artist Statement
                        </ Typography>
                        <Typography>
                            My work ranges from semi- to full abstraction. Each painting begins spontaneously from either colour or form, inspired by nature or the landscape around me. My initial approach is intuitive, spontaneous, and expressive which consequently sparks a wide range of mark-making and mixed media.  The medium I work with mainly is acrylic paint, collage, and pastels; with my favoured palette usually colourful, though I tend towards cool colours.
                        </Typography>
                        <Typography>
                            Beginning with collage and large mark-making, the painting evolves through additions and subtractions of many layers to create its own story, generally concluding with delicate, sometimes colourful, marks.
                        </Typography>
                        <Typography>
                            From seeing and photographing a vista, to a particular colour combination within nature, my art is an expression of that interaction, wonder and curiosity. My joy is for the art to generate an active connection with the viewer and for it to maintain a consistent dialogue.
                        </Typography>
                        <Typography>
                            I have been a member of Wildwood Artists since January 2021 and successfully exhibited in the first “In My Mind’s Eye” 2021 exhibition.
                        </Typography>
                        <Typography>
                            My artistic advice.... don't think of the end-goal, just enjoy the process.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
