import { orange } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

declare module '@mui/material/styles' {
    interface Theme {
        status: {
            danger: string;
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

const theme = createTheme({
    status: {
        danger: orange[500],
    },
    typography: {
        body1: {
          marginTop: 10,
        },
        h1: {
            fontFamily: [
                'Nothing You Could Do',
                'cursive',
            ].join(',')
        }
    }
});

export default theme;