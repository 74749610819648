import { Box, Button, ButtonGroup, Grid, Paper, Typography } from "@mui/material";
import { Container } from "@mui/system";
// @ts-ignore
import TitleBar from "../TitleBar/TitleBar.tsx";
import dateFormat from "dateformat";
import { IExhibition } from './IExhibition';
// @ts-ignore
import ExhibitionCard from "./ExhibitionCard.tsx";

const exhibitions: IExhibition[] = [{
    id: 1,
    title: "Wildwood Artists - In My Mind's Eye",
    image: '/images/IMME-2021.png',
    description: "Wildwood Artists will be joining established artist Liz Hauck, and well-known glass-maker Adam Aaronson, for their second exhibition, ‘In my mind’s eye…’ from 4-12th September 2021 in the beautiful surroundings of High Clandon Estate Vineyard. Seven artists will be exhibiting paintings, both large and small, including four new members of the group. Join us to enjoy art and wine in a beautiful setting in the Surrey Hills!",
    website: {
        url: 'https://www.wildwoodartists.com/exhibitions',
        text: 'wildwoodartists.com'
    },
    contact: {
        instagram: '@wildwoodartists',
        facebook: 'wildwoodartists',
        email: 'wildwoodartists@gmail.com',
    },
    location: {
        name: 'High Clandon Estate Vineyeard, East Clandon',
    },
    date: {
        startDate: new Date('2022/09/04'),
        endDate: new Date('2022/09/12')
    }
},{
    id: 2,
    title: "Wildwood Artists - In My Mind's Eye",
    image: '/images/SOAS-2022.webp',
    description: "The Wildwood Artists will be participating for the first time in the Surrey Artist's Open Studio event this summer. They will be exhibiting together with Liz Hauck, Adam Aaronson and Friends as the group studio No.166.",
    website: {
        url: 'https://surreyopenstudios.org.uk/open-studio/in-my-minds-eye/',
        text: 'surreyopenstudios.org.uk'
    },
    contact: {
        instagram: '@wildwoodartists',
        facebook: 'wildwoodartists',
        email: 'wildwoodartists@gmail.com'
    },
    location: {
        name: 'The Barn, West Horseley Place',
        googleMaps: 'https://goo.gl/maps/AqX4oEzjqXssK2FV8'
    },
    date: {
        startDate: new Date('2022/06/15'),
        endDate: new Date('2022/06/20')
    }
},{
    id: 2,
    title: "Wildwood Artists - In My Mind's Eye",
    image: '/images/my-minds-eye-2024.jpg',
    description: "The Wildwood Artists will be presenting their collection at Place Barn alongsize Liz Hauck.",
    contact: {
        instagram: '@wildwoodartists',
        facebook: 'wildwoodartists',
        email: 'wildwoodartists@gmail.com'
    },
    location: {
        name: 'The Barn, West Horseley Place',
        googleMaps: 'https://goo.gl/maps/AqX4oEzjqXssK2FV8'
    },
    date: {
        startDate: new Date('2024/05/14'),
        endDate: new Date('2024/05/19')
    }
}];

export default function Exhibitions() {
    const sortedExhibitions = exhibitions.sort((a,b) => Number(a.date.startDate) - Number(b.date.startDate));
    let upcomingExhibitionDiv = <div></div>
    let currentExhibitionDiv = <div></div>
    if (sortedExhibitions[0].date.startDate && sortedExhibitions[0].date.startDate > new Date()) {
        upcomingExhibitionDiv = <Typography
            variant="subtitle1">
            The next upcoming exhibition is <b>{sortedExhibitions[0].title}</b> on the {dateFormat(sortedExhibitions[0].date.startDate, 'dS mmmm')} at {sortedExhibitions[0].location.name}.
        </Typography>
    }
    const currentExhibition = exhibitions.find(exhibition => {
        const today = new Date();
        return (exhibition.date.startDate && exhibition.date.startDate <= today && exhibition.date.endDate && exhibition.date.endDate);
    })
    if (currentExhibition) {
        currentExhibitionDiv = <Typography
            variant="subtitle1">
            I am currently exhibiting in <b>{currentExhibition.title}</b> until the {dateFormat(currentExhibition.date.endDate, 'dS mmmm')} at {currentExhibition.location.name}.
        </Typography>
    }
    return (
        <Box sx={{ flexGrow: 1 }}>
            <TitleBar />
            <Container maxWidth="lg">
                <Typography
                    variant="h4">
                    Exhibitions
                </ Typography>
                {upcomingExhibitionDiv}
                {currentExhibitionDiv}
                <Grid
                    container
                    direction="column"
                    spacing={1}>
                    {sortedExhibitions.map((exhibition, i) => (
                        <Grid item key={i}>
                            <ExhibitionCard exhibition={exhibition}/>
                        </Grid>
                    ))} 
                </Grid>
            </Container>
        </Box>
    );
  }